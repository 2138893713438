import axios from 'axios'
import router from '../router'
import Vue from 'vue'

// const ApiHost = 'http://116.62.37.221:908/'
const ApiHost="https://fzbygapi.ubooto.com/"
// const ApiHost="http://127.0.0.1:8089/"
const UploadUrl = 'http://116.62.37.221:908/Api/Qiniu/Upload'
// const ApiHost = 'https://fzsbygapi.i77.com.cn/'
// const UploadUrl = 'https://fzsbygapi.i77.com.cn/Api/Qiniu/Upload'
const _api = {
  SendSMS: 'Api/SMS/Send',
  Login: 'Api/AdminAccount/PCLogin',
  GetAccount: 'Api/AdminAccount/GetSysAccount',
  GetAdminType: 'Api/AdminAccount/GetAdminType',
  GetFloors: 'Api/Floor/GetList',
  GetAdminList: 'Api/AdminAccount/GetList',
  GetAdminListA: 'Api/AdminAccount/PcGetList',
  AddAdmin: 'Api/AdminAccount/Add',
  UpdateType: 'Api/AdminAccount/Update',
  DeleteAdmin: 'Api/AdminAccount/Delete',
  CloseAdmin: 'Api/AdminAccount/Close',
  OpenAdmin: 'Api/AdminAccount/Open',
  GetDelegateList: 'Api/DelegateAdmin/GetList',
  GetDelegateImgList: 'Api/DelegateAdmin/GetImgList',
  ExportDelegate: 'Api/DelegateAdmin/Export',
  GetDelegateDetail: 'Api/DelegateAdmin/Get',
  SetBelong: 'Api/DelegateAdmin/SetBelong',
  MarkDelegate: 'Api/DelegateAdmin/Mark',
  ReviewDelegate: 'Api/DelegateAdmin/Review',
  GetOrderList: 'Api/DelegateAdmin/GetOrderList',
  GetOrderStat: 'Api/DelegateAdmin/GetOrderStat',
  GetAppointmentOrders: 'Api/AppointmentAdmin/GetAppointmentOrders',
  GetOnlineNotice: 'Api/Notice/GetOnlineNotice',
  UpdateNotice: 'Api/Notice/Update',
  Test: 'Api/Values/Send',
  RecallList:'Api/RecallAdmin/GetList',
  RecallUpdate:'Api/RecallAdmin/Update',
  DoDelRecall:'Api/RecallAdmin/Del',
  WechatStatics:'Api/wechatStatics/index',
  GetRecallCount:"Api/wechatStatics/yuyueAllCount",
  GetStat: 'Api/DelegateAdmin/Stat',
  GetDelegateSetting: 'Api/Delegate/GetSetting',
  UpdateDelegateSetting: 'Api/Delegate/Setting',
}

const jsonpRequest = function (apiUrl, params, noAuthCallback) {
  var user = currentUser()
  if (user) {
    params.token = user.Token
  }
  var jsonpPromise = new Promise((resolve, reject) => {
    jsonp(ApiHost + apiUrl, params, (err, res) => {
      if (err) {
        console.log(err)
        reject(err)
      } else {
        if (res.status.indexOf('003') === 0 || res.status === '002100') {
          removeToken()
          if (noAuthCallback && typeof (noAuthCallback) === 'function') {
            res.params = params
            noAuthCallback(res)
          } else {
            msg({message: res.msg, type: 'warning'})
            router.push({name: 'adminlogin'})
          }
        } else {
          resolve(res)
        }
      }
    })
  })

  return jsonpPromise
}

const download = function (apiUrl, params) {
  // setLastTime()
  var user = currentUser()
  if (user) {
    params.token = user.Token
  }
  var id = '__jp' + count++
  var enc = encodeURIComponent
  var queryStrs = []
  Object.keys(params).forEach(function (queryName) {
    queryStrs = queryStrs.concat(formatParams(queryName, params[queryName]))
  })
  var url = ApiHost + apiUrl
  var queryStr = flatten(queryStrs).join('&')
  url = `${url}?jsonp=${enc(id)}&${queryStr}`

  window.open(url, '_blank')
  window.close()
}

const request = function (method, apiUrl, params, noAuthCallback) {
  var user = currentUser()
  if (user) {
    params.token = user.token
  }
  // var paramStr = formatParamStr(params)
  var requestPromise = new Promise((resolve, reject) => {
    axios({
      url: apiUrl,
      data: params,
      headers: { 'content-type': 'application/json' },
      method: method
    }).then((e) => {
      let res = e.data
      if (res.status === '1') {
        resolve(res)
      } else if (res.status.indexOf('003') === 0 || res.status === '002100') {
        removeToken()
        if (noAuthCallback && typeof (noAuthCallback) === 'function') {
          res.params = params
          noAuthCallback(res)
        } else {
          removeToken()
          msg({message: res.msg, type: 'warning'})
          router.push({name: 'adminlogin'})
        }
      } else {
        console.log(JSON.stringify(res))
        resolve(res)
      }
    }).catch(function (e) {
      console.log(e)
      reject(e)
    })
  })

  return requestPromise
}

const upload = function (formData) {
  var requestPromise = new Promise((resolve, reject) => {
    axios({
      url: UploadUrl,
      data: formData,
      headers: { 'content-type': 'multipart/form-data' },
      method: 'post'
    }).then(function (res) {
      resolve(res.data)
    }).catch(function (err) {
      reject(err)
    })
  })

  return requestPromise
}

const apiGet = function (action, param, callback) {
  var apiUrl = ApiHost + action
  return request('get', apiUrl, param, callback)
}

const apiPost = function (action, param, callback) {
  var apiUrl = ApiHost + action
  return request('post', apiUrl, param, callback)
}

var count = new Date().getTime()
/**
 * JSONP handler
 *
 * @param {String} url
 * @param {Object} params params
 * @param {Function} optional callback
 */
function jsonp (url, params, fn) {
  var id = '__jp' + count++
  var timeout = 60000
  var enc = encodeURIComponent
  var target = document.getElementsByTagName('script')[0] || document.head
  var script
  var timer

  if (timeout) {
    timer = setTimeout(function () {
      cleanup()
      if (fn) fn(new Error('Timeout'))
    }, timeout)
  }

  function cleanup () {
    if (script.parentNode) script.parentNode.removeChild(script)
    window[id] = () => { }
    if (timer) clearTimeout(timer)
  }

  function cancel () {
    if (window[id]) {
      cleanup()
    }
  }

  window[id] = function (data) {
    cleanup()
    if (fn) fn(null, data)
  }

  var queryStrs = []
  Object.keys(params).forEach(function (queryName) {
    queryStrs = queryStrs.concat(formatParams(queryName, params[queryName]))
  })
  var queryStr = flatten(queryStrs).join('&')
  url = `${url}?jsonp=${enc(id)}&${queryStr}`
  // create script
  script = document.createElement('script')
  script.src = url
  target.parentNode.insertBefore(script, target)

  return cancel
}

/**
 * Format params into querying string.
 * @param {{}}
 * @return {string[]}
 */
function formatParams (queryName, value) {
  queryName = queryName.replace(/=/g, '')
  var result = []
  if (!value) console.log(queryName)
  switch (value.constructor) {
    case String:
    case Number:
    case Boolean:
      result.push(encodeURIComponent(queryName) + '=' + encodeURIComponent(value))
      break
    case Array:
      value.forEach(function (item) {
        result = result.concat(formatParams(queryName + '[]=', item))
      })
      break
    case Object:
      Object.keys(value).forEach(function (key) {
        var item = value[key]
        result = result.concat(formatParams(queryName + '[' + key + ']', item))
      })
      break
  }

  return result
}

/**
 * Flat querys.
 * @param {any} array
 * @returns
 */
function flatten (array) {
  var querys = []
  array.forEach(function (item) {
    if (typeof item === 'string') {
      querys.push(item)
    } else {
      querys = querys.concat(flatten(item))
    }
  })

  return querys
}

// function formatParamStr (params) {
//   var queryStrs = []
//   Object.keys(params).forEach(function (queryName) {
//     queryStrs = queryStrs.concat(formatParams(queryName, params[queryName]))
//   })
//   return flatten(queryStrs).join('&')
// }

const setToken = function (userinfo) {
  localStorage.setItem('CurrentUser', JSON.stringify(userinfo))
}

/**
 * @param {bool} onlySession 只删除session
 * @returns
 */
const removeToken = function () {
  localStorage.removeItem('CurrentUser')
}

const currentUser = function () {
  var currentUser = localStorage.getItem('CurrentUser')
  return currentUser && JSON.parse(currentUser)
}

var noLogin = 0

const msg = function (option) {
  if (noLogin > 0) return
  if (typeof (option) === 'string') {
    noLogin += 1
    return Vue.prototype.$message({message: option, duration: 2000})
  } else if (!option.duration) {
    noLogin += 1
    option.duration = 2000

  }

  return Vue.prototype.$message(option)
}

// const setDialog = function (alert) {
//   Dialog._alert = alert
// }

export default {
  Apis: _api,
  SetToken: setToken,
  // SetDialog: setDialog,
  RemoveToken: removeToken,
  CurrentUser: currentUser,
  Get: apiGet,
  Post: apiPost,
  Request: jsonpRequest,
  Download: download,
  Upload: upload
}
