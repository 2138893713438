<template>
  <div>
    <div class="formWork">
      <el-date-picker v-model="daterange" type="daterange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-right:10px;">
      </el-date-picker>
      <el-input v-model="mobile" type="text" maxlength="20" placeholder="请输入委托号或手机号"></el-input>
      <el-dropdown trigger="click" @command="switchStatus">
        <el-button type="default">
          {{statusText(status)}}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) in statusLists" :key="index" :command="item.value">{{item.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click" @command="switchOperatorId">
        <el-button type="default">
          {{operatorText(operatorId)}}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) in operatorLists" :key="index" :command="item.id">{{item.nickName}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button @click="search()" type="primary">查询</el-button>
      <el-button @click="reset()">重置</el-button>
    </div>
    <el-table class="margintop10" v-loading="loading" :data="lists">
      <el-table-column prop="orderId" label="委托号" align="center">
      </el-table-column>
      <el-table-column prop="mobile" label="委托人手机号" align="center">
      </el-table-column>
      <el-table-column prop="operatorName" label="祭扫员" align="center">
      </el-table-column>
      <el-table-column prop="operatorMobile" label="祭扫员手机号" align="center">
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
            {{statusText(scope.row.state)}}
        </template>
      </el-table-column>
      <el-table-column prop="operateTime" label="提交审核时间" align="left">
        <template slot-scope="scope">
          {{
            scope.row.operateTime | timeFilter
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <a class="el-button el-button--mini" @click="handleDetail(scope.$index)">查看</a>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <div class="list_page">
          <el-pagination background layout="total, prev, pager, next" :page-size="page.size" :current-page="page.index" @current-change="pageTurning" :total="page.total">
          </el-pagination>
      </div>
    </el-col>

    <!--详情-->
    <el-dialog title="详情" custom-class="kanana" :visible.sync="detailFormVisible" top="5vh" :close-on-press-escape="false" :close-on-click-modal="false" center width="1000px">
      <div>
        <div class="jssao_div">
          <div class="div_half1">
            <p class="pTitle">寄存卡照片</p>
            <p><img :src="indexModel.depositImg" @click="onPreview(indexModel.depositImg)" /></p>
          </div>
          <div class="div_half2 marginleft20">
            <p class="pTitle">详情</p>
            <ul class="ulText">
              <li><span>委托号：</span><label>{{indexModel.orderId}}</label></li>
              <li><span>状态：</span><label >{{statusText(indexModel.state)}}</label></li>
              <li><span>委托人手机号：</span><label>{{indexModel.mobile}}</label></li>
              <li><span>祭扫员：</span><label>{{indexModel.operatorName}}</label></li>
              <li><span>祭扫员手机号：</span><label>{{indexModel.operatorMobile}}</label></li>
            </ul>
            <p class="pTitle" style="margin-top:20px;">委托项目</p>
            <ul class="ulText">
              <li v-for="(item,index) in indexModel.Remarks" :key="index" style="width:100%;"><span>{{item}}</span></li>
            </ul>
          </div>
        </div>
        <div class="divimgs margintop20">
          <p class="pTitle">祭扫照片</p>
          <div><img :src="indexModel.Img1" @click="onPreview(indexModel.Img1)" /><p>祭扫前</p></div>
          <div><img :src="indexModel.Img2" @click="onPreview(indexModel.Img2)" /><p>祭扫后</p></div>
          <div v-if="indexModel.Video2">
              <video :src="indexModel.Video2" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
            </div>
        </div>
        <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="url"  z-index="100000"/>
      </div>
      <div slot="footer" class="dialog-footer" v-if="indexModel.state == 15 || indexModel.state == 20">
        <el-button @click.native="ReviewDelegate(11)">审核驳回</el-button>
        <el-button v-if="indexModel.state == 15" type="primary" @click.native="ReviewDelegate(20)">审核通过</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import ApiClient from '../../utils/apiclient'
import {parseTime} from '../../utils/index'
export default {
  components: { ElImageViewer },
filters: {
      timeFilter(times) {
        if(times!=null){
return parseTime(times)
        }else{
          return ""
        }
        
      },
    },
  data () {
    return {
      page: {
        index: 1,
        size: 10,
        count: 0,
        total: 0
      },
      statusLists: [{value: 0, name: '全部'}, {value: 9, name: '异常委托'}, {value: 10, name: '待祭扫'}, {value: 11, name: '审核驳回'}, {value: 15, name: '待审核'}, {value: 20, name: '已完成'}, {value: 30, name: '重复委托'}],
      status: 15,
      operatorId: 0,
      mobile: '',
      daterange: '',
      indexModel: {
        Media: []
      },
      detailFormVisible: false,
      showViewer: false,
      loading: false,
      url: [],
      operatorLists: [],
      lists: []
    }
  },
  methods: {
    search () {
      this.page.index = 1
      this.page.size = 10
      this.GetDelegateList()
    },
    reset () {
      this.page.index = 1
      this.page.size = 10
      this.mobile = ''
      this.daterange = ''
      this.status = 15
      this.operatorId = 0
      this.GetDelegateList()
    },
    onPreview (url) {
      this.showViewer = true
      this.url = [url]
    },
    closeViewer () {
      this.showViewer = false
    },
    switchStatus (val) {
      this.status = val
    },
    switchOperatorId (val) {
      this.operatorId = val
    },
    statusText (val) {
      for (var i = 0; i < this.statusLists.length; i++) {
        if (val === this.statusLists[i].value) {
          return this.statusLists[i].name
        }
      }
      return ''
    },
    operatorText (val) {
      for (var i = 0; i < this.operatorLists.length; i++) {
        if (val === this.operatorLists[i].id) {
          return this.operatorLists[i].nickName
        }
      }
      return ''
    },
    handleDetail (index) {
      // this.$router.push({ path: '/admin/customerExamineDetail' })
      var model = this.lists[index]
      var param = {
        id: model.id
      }
      ApiClient.Post(ApiClient.Apis.GetDelegateDetail, param).then((e) => {
        console.log(e);
        this.indexModel = e.data.order
        this.indexModel.Remarks = JSON.parse(this.indexModel.remark)
        for (var i = 0; i < e.data.Media.length; i++) {
          var item = e.data.Media[i]
          if (item.mediaType === 1) {
            if (item.type === 1) {
              this.indexModel.Img1 = item.url + '?imageView2/2/w/480/'
            } else if (item.type === 2) {
              this.indexModel.Img2 = item.url + '?imageView2/2/w/480/'
            }
          } else if (item.mediaType === 2) {
            if (item.type === 2) {
              this.indexModel.Video2 = item.url
            }
          }
        }
        this.detailFormVisible = true
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    ReviewDelegate (state) {
      var text = state === 11 ? '确认驳回审核？' : '确认审核通过？'
      this.$confirm(text, '确认操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var param = {
          id: this.indexModel.id,
          state: state
        }
        ApiClient.Post(ApiClient.Apis.ReviewDelegate, param).then(() => {
          this.GetDelegateList()
          this.detailFormVisible = false
        }).catch(err => {
          this.$alert(err.msg, '错误')
        })
      })
    },
    GetAdminList () {
      const param = {
        status: 1,
        type: 3,
      }
      ApiClient.Post(ApiClient.Apis.GetAdminListA, param).then((e) => {
        this.operatorLists = e.data
        this.operatorLists.unshift({'id': 0, 'nickName': '全部'})
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    GetDelegateList () {
      this.loading = true
      var param = {
        pageIndex: this.page.index,
        pageSize: this.page.size
      }
      if (this.mobile) {
        param.mobile = this.mobile
      }
      if (this.status && this.status !== 0) {
        param.state = this.status
      }
      if (this.operatorId && this.operatorId !== 0) {
        param.operatorId = this.operatorId
      }
      if (this.daterange) {
        param.optTimeStart = this.daterange[0] + ' 00:00:00'
        param.optTimeEnd = this.daterange[1] + ' 23:59:59'
      }
      ApiClient.Post(ApiClient.Apis.GetDelegateList, param).then((e) => {
        this.loading = false
        this.lists = e.data
        console.log(this.lists)
        // this.page = e.page
        this.page.total=e.totalCount
        this.page.count=e.pageSize
      }).catch(err => {
        this.loading = false
        this.$alert(err.msg, '错误')
      })
    },
    pageTurning (index) {
      this.page.index = index
      this.GetDelegateList()
    }
  },
  created () {
    this.GetAdminList()
    this.GetDelegateList()
  }
}
</script>
