<template>
  <div class="index_bg">
    <div class="body_div">
      <div class="index_top">
        <div></div>
        <span>人生驿站 | 福州市殡仪馆</span>
      </div>
      <div class="body_div_center messages">
        <div class="foroverflow">
          <p style="text-align:center; font-weight:bold; font-size:34px;">公<label style="display:inline-block; width:30px;"></label>告</p>
          <label style="width:40px; display:inline-block; margin-top:10px;"></label>{{notice.notice}}
        </div>
      </div>
    </div>
    <div class="div_d1">
      <p class="d1_tit">追思馆</p>
      <p class="d1_tit1">致：至亲至爱的人</p>
    </div>
    <div style="position:absolute; left:0; width:100%; bottom:30px; text-align:center;">
      <p style="margin-bottom:40px;"><el-button type="warning" round style="font-size:40px;" @click="dialogVisible = true">免费创建追思馆</el-button></p>
      <p style="margin-bottom:40px;"><a href="https://fzbyg.ubooto.com/miniinfo.html" class="a_formini" target="_blank">委托祭扫</a></p>
      <p class="fontcolorfff fontsize16">版权所有：福州市殡仪馆</p>
    </div>
    <el-dialog
      title="免费创建追思馆"
      :visible.sync="dialogVisible"
      width="400px">
      <div class="dialogDiv">
        <el-image :src="src"></el-image>
        <span>微信扫一扫打开小程序</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApiClient from '../utils/apiclient'

export default {
  name: 'app',
  data () {
    return {
      notice: '',
      dialogVisible: false,
      src: 'https://fzbyg.ubooto.com/gh_80189489addd_860.jpg'
    }
  },
  methods: {
    GetOnlineNotice () {
      var param = {}
      ApiClient.Post(ApiClient.Apis.GetOnlineNotice, param).then((e) => {
        this.notice = e.data
      })
    }
  },
  created () {
    this.GetOnlineNotice()
  }
}
</script>
