/**
 * 手机号
 */
function checkTel (text) {
  // return /^1[345789]\d{9}$/.test(text)
  return /^\d{11}$/.test(text)
}

/**
 * 银行卡号
 */
function checkCardNo (text) {
  return /^\d{16,19}$/.test(text)
}

/**
 * 身份证号
 */
function checkIdcard (text) {
  return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(text)
}

/**
 * 6-20位密码
 */
function checkPassword (text) {
  return /^[\S]{6,20}$/.test(text)
}

/**
 * 6-20位非纯数字或字母
 */
function checkPassword2 (text) {
  return /^(?![^A-Za-z]+$)(?![^0-9]+$)[\x21-x7e]{6,20}$/.test(text)
}

/**
 * 6位数字
 */
function checkMobileCode (text) {
  return /^\d{6}$/.test(text)
}

/**
 * 金额输入格式
 */
function checkAmount (text) {
  return /^[0-9]+(.[0-9]{1,2})?$/.test(text)
}

/**
 * 姓名 2-6位汉字
 */
function checkName () {
  return /^[\u4E00-\u9FA5]{2,10}$/
}

function randomString (len) {
  len = len || 32
  // 默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var maxPos = $chars.length
  var pwd = ''
  for (var i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

function showAmount (amount, fraction) {
  fraction = fraction > 0 && fraction <= 20 ? fraction : 2
  amount = parseFloat((amount + '').replace(/[^\d.-]/g, '')).toFixed(fraction) + ''
  let l = amount.split('.')[0].split('').reverse()
  let r = amount.split('.')[1]
  let t = ''
  for (var i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '')
  }
  return t.split('').reverse().join('') + '.' + r
}

function showPrice (price) {
  if (price === 0) {
    return 0
  }
  if (!price) {
    return '-'
  }
  if (typeof (price) === 'string') {
    price = parseFloat(price)
  }

  return price.toFixed(2)
}

function getDecimal (number) {
  if (!number) return '00'
  var arr = parseFloat(number).toFixed(2).split('.')
  return arr[1]
}

function formatAmount (amount) {
  // 只允许输入带2位小数的数字
  amount = amount.replace(/[^0-9.]/g, '')
  var dot = amount.indexOf('.')
  return /^\d+\.?\d{0,2}$/.test(amount) ? amount : amount.substring(0, dot + 3)
}

function startWith (str, start) {
  var reg = new RegExp('^' + start)
  return reg.test(str)
}

function endWith (str, end) {
  var reg = new RegExp(end + '$')
  return reg.test(str)
}

function dateFormat (date, fmt) {
  var o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S': date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}

function showDate (date) {
  if (typeof (date) === 'string') { date = new Date(date.replace(/-/g, '/')) }
  var compareDate = new Date(dateFormat(date, 'yyyy-MM-dd'))
  var now = new Date()
  var day = ''

  var diffDays = parseInt((now.getTime() - compareDate.getTime()) / (1000 * 60 * 60 * 24))
  if (diffDays === 0) {
    day = '今天'
  } else if (diffDays === 1) {
    day = '昨天'
  // } else if (diffDays === 2) {
  //   day = '前天'
  } else { day = dateFormat(date, 'MM-dd') }
  // else { day = dateFormat(date, 'yyyy-MM-dd') }

  return `${day} ${dateFormat(date, 'hh:mm')}`
}

function getFileType (filetype) {
  if (filetype.match('^image/')) {
    return 'img'
  } else if (filetype.match(/pdf$/)) {
    return 'pdf'
  } else if (filetype.match(/(msword|wordprocessingml.document)$/)) {
    return 'word'
  } else if (filetype.match(/(ms-excel|spreadsheetml.sheet)$/)) {
    return 'excel'
  }
}

function isInArea (dom, position) {
  var y1 = dom.offset().top
  var y2 = y1 + dom.height()
  var x1 = dom.offset().left
  var x2 = x1 + dom.width()
  if (position.x > x1 && position.x < x2 && position.y > y1 && position.y < y2) {
    return true
  }

  return false
}

/**
 * 指定字节长度截取字符串
 * @param {string} pStr 字符串
 * @param {int} pLen 截取长度
 */
function substrByByte (pStr, pLen) {
  var _strLen = pStr.length
  var _cutString
  var _lenCount = 0
  var _ret = false
  if (_strLen <= pLen / 2) {
    _cutString = pStr
    _ret = true
  }

  if (!_ret) {
    for (var i = 0; i < _strLen; i++) {
      if (isFull(pStr.charAt(i))) {
        _lenCount += 2
      } else {
        _lenCount += 1
      }

      if (_lenCount > pLen) {
        _cutString = pStr.substring(0, i)
        _ret = true
        break
      } else if (_lenCount === pLen) {
        _cutString = pStr.substring(0, i + 1)
        _ret = true
        break
      }
    }
  }

  if (!_ret) {
    _cutString = pStr
    _ret = true
  }

  // return { 'cutstring': _cutString, 'cutflag': _cutFlag }
  return _cutString
}

/**
 * 判断是否为全角
 * @param {char} pChar
 */
function isFull (pChar) {
  if ((pChar.charCodeAt(0) > 128)) {
    return true
  } else {
    return false
  }
}

function clone (obj) {
  var o
  if (typeof obj === 'object') {
    if (obj === null) {
      o = null
    } else {
      if (obj instanceof Array) {
        o = []
        for (var i = 0, len = obj.length; i < len; i++) {
          o.push(clone(obj[i]))
        }
      } else {
        o = {}
        for (var j in obj) {
          o[j] = clone(obj[j])
        }
      }
    }
  } else {
    o = obj
  }
  return o
}

function trimStart (str, s) {
  s = (s || '\\s') // 没有传入参数的，默认去空格
  s = ('(' + s + ')')
  var regTrim = new RegExp('^' + s + '*', 'g') // 拼正则
  return str.replace(regTrim, '')
}

function trimEnd (str, s) {
  s = (s || '\\s')
  s = ('(' + s + ')')
  var regTrim = new RegExp(s + '*$', 'g')
  return str.replace(regTrim, '')
}

function trim (str, s) {
  s = (s || '\\s')
  s = ('(' + s + ')')
  var regTrim = new RegExp('(^' + s + '*)|(' + s + '*$)', 'g')
  return str.replace(regTrim, '')
}

/* 数组扩展 */
function distinct (arr) {
  return arr.filter(function (element, index, self) {
    return self.indexOf(element) === index
  })
}

function contain (arr, item) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === item) {
      return true
    }
  }

  return false
}

function remove (arr, item) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === item) {
      arr.splice(i, 1)
    }
  }

  return arr
}

/* 数组扩展-end */

/**
 * 计算时间差(s)
 * @param {结束时间} end
 * @param {开始时间} start
 */
function timeSpan (end, start) {
  let ms = end - start
  return parseInt(ms * 0.001)
}

function getBrowserInfo () {
  var terminal = {}
  var ua = navigator.userAgent.toLowerCase()
  var re = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
  var m = ua.match(re)
  if (m) {
    terminal.browser = m[1].replace(/version/, 'safari')
    terminal.verion = m[2]
  } else if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    terminal.browser = 'ie'
    terminal.verion = ''
  } else {
    terminal.browser = ua
    terminal.verion = ''
  }

  return terminal
}

/* eslint-disable*/
function utf8ByteToUnicodeStr (n) {
  for (var g = '', p = 0; p < n.length; ) {
    var e = n[p],a = 0;
    e >>> 7 == 0
      ? ((g += String.fromCharCode(n[p])), (p += 1))
      : 252 == (252 & e)
      ? ((a = (3 & n[p]) << 30),
        (a |= (63 & n[p + 1]) << 24),
        (a |= (63 & n[p + 2]) << 18),
        (a |= (63 & n[p + 3]) << 12),
        (a |= (63 & n[p + 4]) << 6),
        (a |= 63 & n[p + 5]),
        (g += String.fromCharCode(a)),
        (p += 6))
      : 248 == (248 & e)
      ? ((a = (7 & n[p]) << 24),
        (a |= (63 & n[p + 1]) << 18),
        (a |= (63 & n[p + 2]) << 12),
        (a |= (63 & n[p + 3]) << 6),
        (a |= 63 & n[p + 4]),
        (g += String.fromCharCode(a)),
        (p += 5))
      : 240 == (240 & e)
      ? ((a = (15 & n[p]) << 18),
        (a |= (63 & n[p + 1]) << 12),
        (a |= (63 & n[p + 2]) << 6),
        (a |= 63 & n[p + 3]),
        (g += String.fromCharCode(a)),
        (p += 4))
      : 224 == (224 & e)
      ? ((a = (31 & n[p]) << 12),
        (a |= (63 & n[p + 1]) << 6),
        (a |= 63 & n[p + 2]),
        (g += String.fromCharCode(a)),
        (p += 3))
      : 192 == (192 & e)
      ? ((a = (63 & n[p]) << 6),
        (a |= 63 & n[p + 1]),
        (g += String.fromCharCode(a)),
        (p += 2))
      : ((g += String.fromCharCode(n[p])), (p += 1));
  }

  return g;
}
/* eslint-enable */

function json2buffer (data) {
  var r = getUnicodeArray(JSON.stringify(data))
  var t = r.length
  var o = new ArrayBuffer(4)
  new DataView(o).setUint32(0, t)
  var s = new Uint8Array(4 + t)
  s.set(new Uint8Array(o), 0)
  s.set(r, 4)
  return s.buffer
}

function getUnicodeArray (str) {
  for (
    var n = new ArrayBuffer(2 * str.length),
      r = new Uint16Array(n),
      t = 0,
      o = str.length;
    t < o;
    t++
  ) { r[t] = str.charCodeAt(t) }
  return r
}

/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {Promise}
 */
function getBlob (url) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest()

    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      }
    }

    xhr.send()
  })
}

/**
* 保存
* @param  {Blob} blob
* @param  {String} filename 想要保存的文件名称
*/
function saveAs (blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    const body = document.querySelector('body')

    link.href = window.URL.createObjectURL(blob)
    link.download = filename

    // fix Firefox
    link.style.display = 'none'
    body.appendChild(link)

    link.click()
    body.removeChild(link)

    window.URL.revokeObjectURL(link.href)
  }
}

/**
* 下载
* @param  {String} url 目标文件地址
* @param  {String} filename 想要保存的文件名称
*/
function download (url, filename) {
  getBlob(url).then(blob => {
    saveAs(blob, filename)
  })
}

export default {
  checkTel,
  checkCardNo,
  checkIdcard,
  checkPassword,
  checkPassword2,
  checkMobileCode,
  checkAmount,
  checkName,
  clone,
  contain,
  distinct,
  randomString,
  remove,
  showAmount,
  showPrice,
  formatAmount,
  getDecimal,
  startWith,
  endWith,
  dateFormat,
  showDate,
  getFileType,
  substrByByte,
  isInArea,
  download,
  timeSpan,
  trim,
  trimEnd,
  trimStart,
  getBrowserInfo,
  json2buffer,
  getUnicodeArray,
  utf8ByteToUnicodeStr
}
