<template>
  <div>
    <el-container>
      <el-aside width="200px" :style="viewHeight">
        <div class="aside_user">
          <img src="../assets/images/timglogo.png" />
          <label>福州市殡仪馆管理后台</label>
        </div>
        <el-menu :default-active="active">
          <el-menu-item index="员工管理" @click="switchMenu(1)" v-if="user.type==1 || user.type==2"><i class="el-icon-user margintop_fu4"></i>员工管理</el-menu-item>
          <!-- <el-menu-item index="预约查询" @click="switchMenu(2)"><i class="el-icon-search margintop_fu4"></i>预约查询</el-menu-item> -->
          <el-menu-item index="公告管理" @click="switchMenu(3)" v-if="user.type==1 || user.type==2"><i class="el-icon-files margintop_fu4"></i>公告管理</el-menu-item>
          
          <el-menu-item index="委托列表" @click="switchMenu(7)" v-if="user.type==1 || user.type==2 || user.type==7"><i class="el-icon-c-scale-to-original margintop_fu4"></i>委托列表</el-menu-item>
          
          <!-- <el-menu-item index="祭祀人员查询" @click="switchMenu(5)"><i class="el-icon-postcard margintop_fu4"></i>祭祀人员查询</el-menu-item> -->
          <el-menu-item index="祭祀照片审核" @click="switchMenu(6)" v-if="user.type==1 || user.type==2 || user.type==5"><i class="el-icon-picture-outline margintop_fu4"></i>祭祀照片审核</el-menu-item>
          <el-menu-item index="追思馆列表" @click="switchMenu(8)" v-if="user.type==1 || user.type==2"><i class="el-icon-files margintop_fu4"></i>追思馆列表</el-menu-item>
          <el-menu-item index="微信数据统计" @click="switchMenu(4)" v-if="user.type==1 || user.type==2"><i class="el-icon-postcard margintop_fu4"></i>微信统计</el-menu-item>
          <el-menu-item index="数据统计" @click="switchMenu(5)" v-if="user.type==1 || user.type==2"><i class="el-icon-postcard margintop_fu4"></i>数据统计</el-menu-item>
          <el-menu-item index="系统设置" @click="switchMenu(99)" v-if="user.type==1 || user.type==2"><i class="el-icon-postcard margintop_fu4"></i>系统设置</el-menu-item>
        
        </el-menu>
      </el-aside>

      <el-container>
        <el-header>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link pointer">
              <i class="el-icon-setting ic1"></i>{{sysUserName}}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item><label class="fontcolorff4400 cursor" @click="logout">退出</label></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-header>
        <el-main :style="bodyHeight">
          <span class="nvg" @click="naviTo(pageTitle.href)" ><i v-if="pageTitle.href" class="el-icon-back"></i> {{pageTitle.text}}</span>
          <router-view v-if="isRouterAlive"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import ApiClient from '../utils/apiclient'

export default {
  provide () {
    return {
      reload: this.reload,
      setTitle: this.setTitle,
      switchMenu: this.switchMenu,
      setSubMenus: this.setSubMenus
    }
  },
  data () {
    return {
      active: '',
      sysUserName: '',
      user: {},
      isRouterAlive: true,
      viewHeight: {
        height: '0',
        background: '#f4f4f4'
      },
      bodyHeight: {
        height: '0',
        background: '#fff'
      },
      pageTitle: {
        text: '',
        href: ''
      }
    }
  },
  mounted () {
    this.active = this.$router.history.router.currentRoute.name
    this.setTitle(this.$router.history.router.currentRoute.name)
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    resize () {
      this.viewHeight.height = window.innerHeight + 'px'
      this.bodyHeight.height = (window.innerHeight - 60) + 'px'
    },
    naviTo (router, name) {
      console.log(router, name)
      if (name) {
        this.setTitle(name)
      }
      this.$router.replace(router)
      this.reload()
    },
    setTitle (title, url) {
      this.pageTitle.text = title === 'hello' ? '' : title
      this.pageTitle.href = url || ''
    },
    logout () {
      ApiClient.RemoveToken()
      this.$router.push({name: 'adminlogin'})
    },
    // GetAccount () {
    //   ApiClient.Post(ApiClient.Apis.GetAccount, {}).then((e) => {
    //     console.log(e)
    //   }).catch(err => {
    //     this.$message({message: err.msg, type: 'error', center: true})
    //   })
    // },
    switchMenu (index) {
      switch (index) {
        case 1:
          this.naviTo('/admin/staff', '员工管理')
          break
        case 2:
          this.naviTo('/admin/appoint', '预约查询')
          break
        case 3:
          this.naviTo('/admin/notice', '公告管理')
          break
        case 4:
          this.naviTo('/admin/wechatStatic', '微信数据统计')
          break
        case 5:
          this.naviTo('/admin/recallStatic', '数据统计')
          break
        case 6:
          this.naviTo('/admin/customerExamine', '祭祀照片审核')
          break
        case 7:
          this.naviTo('/admin/allot', '委托列表')
          break
        case 8:
          this.naviTo("/admin/recalla",'追思馆列表')
          break;
        case 99:
          this.naviTo("/admin/systemSetting",'系统设置')

      }
    }
  },
  created () {
    this.resize()
    window.addEventListener('resize', this.resize)
    console.log(this.$router)
    var user = ApiClient.CurrentUser()
    if (user && user.token) {
      this.user = user
      this.sysUserName = user.nickName
      // this.GetAccount()
    } else {
      this.$router.push({name: 'adminlogin'})
      return
    }
    console.log(11)
    console.log(this.$router.history.router.currentRoute.name)
    if (this.$router.history.router.currentRoute.name === 'admin') {
      this.$router.replace('/admin/hello')
    }
    // console.log(this.$router.options.routes)
  }
}
</script>
