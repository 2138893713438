<template>
  <div style="text-align: center;">
    <div style=" margin: 200px 200px;">
        <div>
          <img style="width: 70px;height: 70px;" src="../assets/images/timglogo.png" />
        </div>
        <div style="margin-top:10px;">
            <div>
                欢迎回来！
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      msg: 'Welcome !'
    }
  }
}
</script>

<style>
.lockscreen-item {
  padding: 0;
  background: #fff;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin: 10px auto;
  width: 290px;
}
.lockscreen-item > .lockscreen-image {
  position: absolute;
  left: -10px;
  top: -30px;
  background: #fff;
  padding: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 10;
}
.lockscreen-item > .lockscreen-image > img {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.lockscreen-item > .lockscreen-credentials {
  margin-left: 80px;
}
.input-group .input-group-addon {
  border-radius: 0;
  background-color: #f4f4f4;
}
</style>
