<template>
  <div>
    <div class="formWork">
     
      <el-select v-model="status" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="search()" type="primary">查询</el-button>
    </div>
    <el-table class="margintop10" v-loading="loading" :data="lists">
      <el-table-column prop="phone" label="发布人手机号" align="center"/>
      <el-table-column prop="name" label="逝者姓名" align="center"/>
      <el-table-column prop="relation" label="尊称" align="center"/>
      <el-table-column prop="content" label="祭言" align="center">
        <template slot-scope="scope">
            <P style="white-space:nowrap;text-overflow:ellipsis;">{{scope.row.content}}</P>
        </template>
      </el-table-column>
      <!-- <el-table-column label="状态" align="center">
        <template slot-scope="scope">
            {{statusText(scope.row.BelongState)}}
        </template>
      </el-table-column> -->
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
            {{scope.row.status|statusFilter}}
        </template>
      </el-table-column>
      <el-table-column prop="orderTime" label="提交时间" align="left">
        <template slot-scope="scope">
            {{scope.row.ctime|timeFilter}}
        </template>
      </el-table-column>
      <el-table-column label="照片头像" align="center">
        <template slot-scope="scope">
          <a v-if="scope.row.avatar!=''" class="el-button el-button--mini" @click="onPreview(scope.row.avatar)">查看</a>
        </template>
      </el-table-column>
     
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <a class="el-button el-button--mini" @click="handleAllot(scope.$index)">查看详情</a>
          <a class="el-button  el-button--mini" style="background-color:red;color:#FFFFFF" @click="delRecall(scope.row.id)">删除</a>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <div class="list_page">
          <el-pagination background layout="total, prev, pager, next" :page-size="page.size" :current-page="page.index" @current-change="pageTurning" :total="page.total">
          </el-pagination>
      </div>
    </el-col>

        <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="url" />
      <el-dialog title="追思馆详情" :visible.sync="editFormVisible" top="5vh" :close-on-click-modal="false" center width="400px" style="margin-top:100px;">
        <div>
          <h3>祭言:</h3>
          <P>{{editmodel.content}}</P>
          <h3 v-if="editmodel.message!=''">时空寄语：</h3>
          <audio  v-if="editmodel.message!=''" id="audio" :src="editmodel.message" controls></audio>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button v-if="editmodel.status==0" type="primary" @click="ChangeStatus(editmodel.id)">审核通过</el-button>
          <el-button @click="closeDailog">取消</el-button>
        </div>
      </el-dialog>

  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import ApiClient from '../../utils/apiclient'
// import Utils from '../../utils/apputils'
import {parseTime} from '../../utils/index'

export default {
  components: { ElImageViewer },
  filters: {
      timeFilter(times) {
        return parseTime(times)
      },
      faildFilter(v){
        if(v==0){
          return "是"
        }else{
          return "否"
        }
      },

      statusFilter(v){
        const statusMap={
          1:"通过",
          0:"待审核"
        }

        return statusMap[v]
      }
    },
  data () {
    return {
      page: {
        index: 1,
        size: 10,
        count: 0,
        total: 0
      },
      daterange: '',
      FloorLists: [],
      floor: 1,
      status: 0,
      statuss:0,
      mobile: '',
      editmodel: {},
      editFormVisible: false,
      showViewer: false,
      loading: false,
      url: [],
      lists: [],
      options: [{
          value: 0,
          label: '待审核'
        }, {
          value: 1,
          label: '已审核'
        }],
    }
  },
  methods: {
    delRecall(v){
      console.log(v);
    this.$confirm("您确认删除这个追思馆吗？这个删除将不可恢复！！！", '确认操作', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            var param = {
              id: v,
            }
            ApiClient.Post(ApiClient.Apis.DoDelRecall, param).then(() => {
              this.GetRecallList()
            }).catch(err => {
              this.$alert(err.msg, '错误')
            })
          })
    },
    ChangeStatus(v){
      var param = {
        id: v,
      }

       ApiClient.Post(ApiClient.Apis.RecallUpdate, param).then((e) => {
         if(e.status=="1"){
           this.editFormVisible=false;
           this.GetRecallList()
         }else{
           this.$alert(e.msg, '错误')
         }
      }).catch(err => {
        this.loading = false
        this.$alert(err.msg, '错误')
      })

    },
    closeDailog(){
      var audio=document.getElementById("audio");
      if(audio!=null){
        audio.pause();
      }
      
      this.editFormVisible =false;
    },
    search () {
      this.page.index = 1
      this.page.size = 10
      this.statuss=this.status
      this.GetRecallList()
    },
    onPreview (url) {
      this.showViewer = true
      this.url = [url]
    },
    closeViewer () {
      this.showViewer = false
    },
    switchStatus (val) {
      this.status = val
    },
    switchFloor (val) {
      this.floor = val
    },
    switchEditFloor (val) {
      var model = this.FloorLists[val]
      this.editmodel.belongId = model.id
      this.editmodel.belong = model.name
    },
    statusText (val) {
      for (var i = 0; i < this.statusLists.length; i++) {
        if (val === this.statusLists[i].value) {
          return this.statusLists[i].name
        }
      }
      return ''
    },
    floorText (val) {
      for (var i = 0; i < this.FloorLists.length; i++) {
        if (val === this.FloorLists[i].id) {
          return this.FloorLists[i].name
        }
      }
      return ''
    },
    handleAllot (index) {
      this.editmodel = this.lists[index]
      this.editFormVisible = true
    },
    
    
    GetRecallList () {
      this.loading = true
      var param = {
        pageIndex: this.page.index,
        pageSize: this.page.size,
        status:this.statuss
      }

      ApiClient.Post(ApiClient.Apis.RecallList, param).then((e) => {
        this.loading = false
        this.lists = e.data
        // this.page = e.page
        this.page.total=e.totalCount;
      }).catch(err => {
        this.loading = false
        this.$alert(err.msg, '错误')
      })
    },
    pageTurning (index) {
      this.page.index = index
      this.GetRecallList()
    }
  },
  created () {
    this.GetRecallList()
  }
}
</script>
