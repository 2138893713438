<template>
  <div>
    <div style="width:100%">
        <el-input v-model="content" type="textarea" :rows="15" maxlength="3000"></el-input>
    </div>
    <div class="formWork">
      <el-button type="primary" class="" @click="handleUpdate">提交保存</el-button>
    </div>
  </div>
</template>

<script>
import ApiClient from '../../utils/apiclient'

export default {
  data () {
    return {
      content:"",
    }
  },
  methods: {
    handleUpdate () {
      const param={
        "notice":this.content
      }
      ApiClient.Post(ApiClient.Apis.UpdateNotice,param).then((e)=>{
        this.$alert(e.msg);
      })
    },
    pageTurning (index) {
      this.page.index = index
      this.GetNoticeList()
    },
    getNotice(){
      ApiClient.Post(ApiClient.Apis.GetOnlineNotice,{}).then((e)=>{
        this.content=e.data.notice;
      })
    }
  },
  created () {
    this.getNotice();
    // this.GetNoticeList()
  }
}
</script>
