import Vue from 'vue'
import axios from 'axios'
import ElementUI from 'element-ui'
import App from './App.vue'
import $ from 'jquery'
import './plugins/element.js'
import router from './router'

Vue.http = Vue.prototype.$http = axios
Vue.prototype.$ = $

Vue.config.productionTip = false

Vue.use(ElementUI)


Vue.prototype.$msg = (option) => {
  if (typeof (option) === 'string') {
    return Vue.prototype.$message({message: option, duration: 1500})
  } else if (!option.duration) {
    option.duration = 1500
  }

  return Vue.prototype.$message(option)
}


let loading // 定义loading变量

function startLoading () { // 使用Element loading-start 方法
  loading = this.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}
function endLoading () { // 使用Element loading-close 方法
  loading.close()
}

Vue.prototype.startLoading = startLoading
Vue.prototype.endLoading = endLoading

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
