<template>
  <div>
    <div class="formWork">
      <el-input v-model="mobile" type="text" maxlength="11" placeholder="请输入手机号"></el-input>
      <el-button type="primary" @click="search()">查询</el-button>
      <el-button @click="reset()">重置</el-button>
      <el-button type="primary" class="floatright margintop5" @click.native="addFormVisible = true">新增</el-button>
    </div>
    <el-table class="margintop10" v-loading="loading" :data="lists">
      <el-table-column prop="nickName" label="姓名" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="手机号" align="center">
      </el-table-column>
      <el-table-column prop="role" label="角色" align="center">
        <template slot-scope="scope">
            {{typeText(scope.row.type)}}
        </template>
      </el-table-column>
      <el-table-column prop="floorName" label="所属楼堂" align="center">
      </el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <a class="el-button el-button--mini" style="margin-right:10px !important;" v-if="scope.row.Status == 2" @click="handleOpen(scope.$index)">启用</a>
          <a class="el-button el-button--mini" style="margin-right:10px !important;" v-else-if="scope.row.Status == 1"  @click="handleClose(scope.$index)">禁用</a>
          <a class="el-button el-button--danger el-button--mini" style="margin-right:10px !important;" @click="handleDel(scope.$index)">删除</a>
          <a class="el-button el-button--mini" @click="handleUpdate(scope.$index)">修改</a>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <div class="list_page">
          <el-pagination background layout="total, prev, pager, next" :page-size="page.size" :current-page="page.index" @current-change="pageTurning" :total="page.total">
          </el-pagination>
      </div>
    </el-col>

    <!--新增界面-->
    <el-dialog title="新增" :visible.sync="addFormVisible" :append-to-body="true" :close-on-click-modal="false" center width="600px">
      <el-form label-width="80px">
        <el-form-item label="姓名">
          <el-input v-model="newmodel.name" type="text" maxlength="20" style="width:80%"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="newmodel.mobile" type="text" maxlength="11" style="width:80%"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-dropdown  @command="switchNewType" trigger="click">
            <el-button type="default">
              {{typeText(newmodel.type)}}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in TypeLists" :key="index" :command="item.type">{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <el-form-item label="所属楼堂">
          <el-dropdown  @command="switchNewFloor" trigger="click">
            <el-button type="default">
              {{floorText(newmodel.floor)}}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in FloorLists" :key="index" :command="item.id">{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="AddAdmin">提交</el-button>
        <el-button @click.native="addFormVisible = false">取消</el-button>
      </div>
    </el-dialog>

    <!--编辑界面-->
    <el-dialog title="修改" :visible.sync="editFormVisible" :close-on-click-modal="false" center width="400px">
      <el-form label-width="80px">
        <el-form-item label="角色">
          <el-dropdown  @command="switchEditType" trigger="click">
            <el-button type="default">
              {{typeText(editmodel.type)}}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in TypeLists" :key="index" :command="item.type">{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <el-form-item label="所属楼堂">
          <el-dropdown  @command="switchEditFloor" trigger="click">
            <el-button type="default">
              {{floorText(editmodel.floor)}}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in FloorLists" :key="index" :command="item.id">{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="UpdateType">确定</el-button>
        <el-button @click.native="editFormVisible = false">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import ApiClient from '../../utils/apiclient'

export default {
  data () {
    return {
      page: {
        index: 1,
        size: 10,
        count: 0,
        total: 0
      },
      mobile: '',
      newmodel: {
        name: '',
        mobile: '',
        floor: 1,
        type: 2
      },
      editmodel: {
        id: '',
        floor: '',
        type: 2
      },
      addFormVisible: false,
      editFormVisible: false,
      loading: false,
      FloorLists: [],
      TypeLists: [],
      lists: []
    }
  },
  methods: {
    search () {
      this.page.index = 1
      this.page.size = 10
      this.GetAdminList()
    },
    reset () {
      this.page.index = 1
      this.page.size = 10
      this.mobile = ''
      this.GetAdminList()
    },
    handleOpen (index) {
      var model = this.lists[index]
      var param = {
        id: model.Id
      }
      ApiClient.Post(ApiClient.Apis.OpenAdmin, param).then(() => {
        this.GetAdminList()
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    handleClose (index) {
      var model = this.lists[index]
      var param = {
        id: model.Id
      }
      ApiClient.Post(ApiClient.Apis.CloseAdmin, param).then(() => {
        this.GetAdminList()
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    handleDel (index) {
      var model = this.lists[index]
      this.$confirm('确认删除账号？', '确认操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var param = {
          id: model.id
        }
        ApiClient.Post(ApiClient.Apis.DeleteAdmin, param).then((e) => {
          if(e.status=="1"){
            this.GetAdminList()
          }else{
            this.$alert(e.msg,'错误')
          }
          
        }).catch(err => {
          this.$alert(err.msg, '错误')
        })
      })
    },
    handleUpdate (index) {
      var model = this.lists[index]
      console.log(model)
      this.editmodel = {
        id: model.id,
        floor: model.floorId,
        type: model.type
      }
      this.editFormVisible = true
    },
    switchNewType (val) {
      console.log(val);
      this.newmodel.type = val
    },
    switchEditType (val) {
      this.editmodel.type = val
    },
    switchNewFloor (val) {
      this.newmodel.floor = val
    },
    switchEditFloor (val) {
      this.editmodel.floor = val
    },
    UpdateType () {
      if (!this.editmodel.id || !this.editmodel.type || !this.editmodel.floor) {
        this.$msg({message: '参数不能为空', type: 'warning'})
        return
      }
      var param = this.editmodel
      ApiClient.Post(ApiClient.Apis.UpdateType, param).then((e) => {
        if(e.status=="1"){
          this.editFormVisible = false
          this.GetAdminList()
        }else{
          this.$alert(e.msg,'错误');
        }
        
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    AddAdmin () {
      if (!this.newmodel.name || !this.newmodel.mobile || !this.newmodel.type || !this.newmodel.floor) {
        this.$msg({message: '参数不能为空', type: 'warning'})
        return
      }
      var param = this.newmodel
      ApiClient.Post(ApiClient.Apis.AddAdmin, param).then((e) => {
        if(e.status=="1"){
        this.addFormVisible = false
        this.GetAdminList()
        this.resetnewmodel()
        }else{
          this.$alert(e.msg,'错误')
        }
        
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    resetnewmodel () {
      this.newmodel = {
        name: '',
        mobile: '',
        floor: 1,
        type: 2
      }
    },
    GetFloors () {
      var param = {
        types: JSON.stringify([1, 2])
      }
      ApiClient.Post(ApiClient.Apis.GetFloors, param).then((e) => {
        console.log(e);
        this.FloorLists = e.data
      })
    },
    GetAdminType () {
      var param = {}
      ApiClient.Post(ApiClient.Apis.GetAdminType, param).then((e) => {
        this.TypeLists = e.data
      })
    },
    typeText (val) {
      for (var i = 0; i < this.TypeLists.length; i++) {
        if (val === this.TypeLists[i].type) {
          return this.TypeLists[i].name
        }
      }
      return ''
    },
    floorText (val) {
      for (var i = 0; i < this.FloorLists.length; i++) {
        if (val === this.FloorLists[i].id) {
          return this.FloorLists[i].name
        }
      }
      return ''
    },
    GetAdminList () {
      this.loading = true
      const param = {
        pageIndex: this.page.index,
        pageSize: this.page.size
      }
      if (this.mobile) {
        param.mobile = this.mobile
      }
      ApiClient.Post(ApiClient.Apis.GetAdminList, param).then((e) => {
        this.loading = false
        this.lists = e.data
        // this.page = e.page
        this.page.total=e.totalCount
      }).catch(err => {
        this.loading = false
        this.$alert(err.msg, '错误')
      })
    },
    pageTurning (index) {
      this.page.index = index
      this.GetAdminList()
    }
  },
  created () {
    var $this = this
    this.GetAdminType()
    this.GetFloors()
    setTimeout(function () {
      $this.GetAdminList()
    }, 500)
  }
}
</script>
