import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '*',
      component: require('@/views/Index').default
    },
    // {
    //   path: '/login',
    //   component: require('@/views/login').default
    // },
    {
      path: '/adminlogin',
      name: 'adminlogin',
      component: require('@/views/adminlogin').default
    },
    {
      path: '/admin',
      name: 'admin',
      component: require('@/views/admin').default,
      children: [
        {
          path: 'hello',
          name: 'hello',
          hide: false,
          component: require('@/views/hello').default
        },
        {
          path: 'staff',
          name: '员工管理',
          hide: false,
          component: require('@/views/staff/index').default
        },
        // {
        //   path: 'appoint',
        //   name: '预约查询',
        //   hide: false,
        //   component: require('@/views/appoint/index').default
        // },
        {
          path: 'notice',
          name: '公告管理',
          hide: false,
          component: require('@/views/notice/index').default
        },
        {
          path: 'allot',
          name: '委托列表',
          hide: false,
          component: require('@/views/allot/index').default
        },{
          path: 'recalla',
          name: '追思馆内容',
          hide: false,
          component: require('@/views/recalla/index').default
        },
         {
          path: 'wechatStatic',
          name: '微信数据统计',
          hide: false,
          component: require('@/views/wechatStatic/index').default
        },
         {
          path: 'recallStatic',
          name: '数据统计',
          hide: false,
          component: require('@/views/recallStatic/index').default
        },
        {
          path: 'systemSetting',
          name: '系统设置',
          hide: false,
          component: require('@/views/systemSetting/index').default
        },
        // {
        //   path: 'invoice',
        //   name: '流水详情',
        //   hide: false,
        //   component: require('@/views/invoice/index').default
        // },
        // {
        //   path: 'customer',
        //   name: '祭祀人员查询',
        //   hide: false,
        //   component: require('@/views/customer/index').default
        // },
        {
          path: 'customerExamine',
          name: '祭祀照片审核',
          hide: false,
          component: require('@/views/customerExamine/index').default
        },
        // {
        //   path: 'customerExamineDetail',
        //   name: '祭祀照片审核-详情',
        //   hide: false,
        //   component: require('@/views/customerExamine/customerExamineDetail').default
        // }
      ]
    }
  ]
})
