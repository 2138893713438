<template>
  <div>
    <div class="formWork">
      <el-date-picker v-model="daterange" type="daterange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-input v-model="mobile" type="text" maxlength="20" placeholder="请输入委托号或手机号" style="margin-left:10px;"></el-input>
      <!-- <el-dropdown trigger="click" @command="switchStatus">
        <el-button type="default">
          {{statusText(status)}}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) in statusLists" :key="index" :command="item.value">{{item.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <el-dropdown trigger="click" @command="switchFloor">
        <el-button type="default">
          {{floorText(floor)}}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) in FloorLists" :key="index" :command="item.id">{{item.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button @click="search()" type="primary">查询</el-button>
      <el-button @click="reset()">重置</el-button>
      <el-button @click="ExportDelegate()" type="primary" style="float:right;">导出Excel</el-button>
      <el-button @click="downImg()" type="primary" style="float:right;">下载照片</el-button>
    </div>
    <el-table class="margintop10" v-loading="loading" :data="lists">
      <el-table-column prop="orderId" label="委托号" align="center">
      </el-table-column>
      <el-table-column prop="mobile" label="委托人手机号" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.mobile}}</span>
          <!-- <span style="margin-left:10px;" @click="MarkDelegate(scope.$index)">
            <i style="font-size:20px;" class="el-icon-star-off" v-if="scope.row.mark==0"></i>
            <i style="font-size:23px;" class="el-icon-star-on" v-else></i>
          </span> -->
        </template>
      </el-table-column>
      <el-table-column prop="belong" label="所属楼堂" align="center">
      </el-table-column>
      <!-- <el-table-column label="状态" align="center">
        <template slot-scope="scope">
            {{statusText(scope.row.BelongState)}}
        </template>
      </el-table-column> -->
      <el-table-column prop="faild" label="是否无需操作" align="center">
        <template slot-scope="scope">
            {{scope.row.faild|faildFilter}}
        </template>
      </el-table-column>
      <el-table-column prop="orderTime" label="提交时间" align="left">
        <template slot-scope="scope">
            {{scope.row.orderTime|timeFilter}}
        </template>
      </el-table-column>
      <el-table-column label="寄存卡照片" align="center">
        <template slot-scope="scope">
          <a class="el-button el-button--mini" @click="onPreview(scope.row.depositImg)">查看</a>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <a class="el-button el-button--mini" @click="handleAllot(scope.$index)">分配</a>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <div class="list_page">
          <el-pagination background layout="total, prev, pager, next" :page-size="page.size" :current-page="page.index" @current-change="pageTurning" :total="page.total">
          </el-pagination>
      </div>
    </el-col>

        <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="url" />
    <!--分配-->
    <el-dialog title="请分配所属楼堂" :visible.sync="editFormVisible" top="5vh" :close-on-click-modal="false" center width="400px" style="margin-top:100px;">
      <div style="height:150px; text-align:center;padding-top:20px;">
        <el-dropdown  @command="switchEditFloor" trigger="click">
          <el-button type="default">
            {{editmodel.belong}}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) in FloorLists" :key="index" :v-if="item.id!=1" :command="index">{{item.name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="Belong">确定</el-button>
        <el-button @click.native="editFormVisible = false">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import ApiClient from '../../utils/apiclient'
import Utils from '../../utils/apputils'
import {parseTime} from '../../utils/index'

export default {
  components: { ElImageViewer },
  filters: {
      timeFilter(times) {
        return parseTime(times)
      },
      faildFilter(v){
        if(v==0){
          return "是"
        }else{
          return "否"
        }
      }
    },
  data () {
    return {
      page: {
        index: 1,
        size: 10,
        count: 0,
        total: 0
      },
      daterange: '',
      FloorLists: [],
      floor: 1,
      statusLists: [{value: 0, name: '全部'}, {value: 1, name: '未分配'}, {value: 2, name: '已分配'}],
      status: 1,
      mobile: '',
      editmodel: {
        id: '',
        belongId: '',
        belong: ''
      },
      editFormVisible: false,
      showViewer: false,
      loading: false,
      url: [],
      lists: []
    }
  },
  methods: {
    search () {
      this.page.index = 1
      this.page.size = 10
      this.GetDelegateList()
    },
    reset () {
      this.page.index = 1
      this.page.size = 10
      this.mobile = ''
      this.status = 1
      this.daterange = ''
      this.floor = 1
      this.GetDelegateList()
    },
    onPreview (url) {
      this.showViewer = true
      this.url = [url]
    },
    closeViewer () {
      this.showViewer = false
    },
    switchStatus (val) {
      this.status = val
    },
    switchFloor (val) {
      this.floor = val
    },
    switchEditFloor (val) {
      var model = this.FloorLists[val]
      this.editmodel.belongId = model.id
      this.editmodel.belong = model.name
    },
    GetFloors () {
      var param = {
        types: JSON.stringify([1, 2, 3, 4])
      }
      ApiClient.Post(ApiClient.Apis.GetFloors, param).then((e) => {
        this.FloorLists = e.data
      })
    },
    statusText (val) {
      for (var i = 0; i < this.statusLists.length; i++) {
        if (val === this.statusLists[i].value) {
          return this.statusLists[i].name
        }
      }
      return ''
    },
    floorText (val) {
      for (var i = 0; i < this.FloorLists.length; i++) {
        if (val === this.FloorLists[i].id) {
          return this.FloorLists[i].name
        }
      }
      return ''
    },
    handleAllot (index) {
      var model = this.lists[index]
      this.editmodel = {
        id: model.id,
        belongId: model.belongId || this.FloorLists[1],
        belong: model.belong || this.FloorLists[1].name
      }
      this.editFormVisible = true
    },
    Belong () {
      if (this.editmodel.belongId === 1001 || this.editmodel.belongId === 1002) {
        var text = this.editmodel.belongId === 1001 ? '确认为非法委托吗？' : '确认为异常委托吗？'
        this.$prompt(text, '确认操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(({ value }) => {
          this.SetBelong(value)
        })
      } else {
        this.SetBelong()
      }
    },
    SetBelong (value) {
      if (!this.editmodel.belongId || this.editmodel.belongId === 1) {
        this.$msg({message: '请分配楼堂', type: 'warning'})
        return
      }
      var param = this.editmodel
      if (this.editmodel.belongId === 1001 || this.editmodel.belongId === 1002 || this.editmodel.belongId === 1000) {
        // if (!value) {
        //   this.$msg({message: '请输入原因', type: 'warning'})
        //   return
        // }
        if (value) {
          param.tip = value
        }
        param.state = 9
      } else if (this.editmodel.belongId !== 1000) {
        param.state = 10
      }
      ApiClient.Post(ApiClient.Apis.SetBelong, param).then(() => {
        this.GetDelegateList()
        this.editFormVisible = false
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    MarkDelegate (index) {
      var model = this.lists[index]
      var param = {
        id: model.Id,
        mark: model.Mark === 0 ? 1 : 0
      }
      ApiClient.Post(ApiClient.Apis.MarkDelegate, param).then(() => {
        this.GetDelegateList()
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    downImg () {
      var param = {}
      if (this.floor !== 1) {
        param.belongId = this.floor
      }
      if (this.mobile) {
        param.mobile = this.mobile
      }
      if (this.daterange) {
        param.start = this.daterange[0] + ' 00:00:00'
        param.end = this.daterange[1] + ' 23:59:59'
      }
      ApiClient.Post(ApiClient.Apis.GetDelegateImgList, param).then((e) => {
        var lists = e.data
        this.$confirm('确认下载' + lists.length + '张照片？', '确认操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var i = 0; i < lists.length; i++) {
            var item = lists[i]
            if (item.depositImg && item.mobile) {
              Utils.download(item.depositImg, item.mobile)
            }
          }
          // this.SetBelong()
        })
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    ExportDelegate () {
      var param = {}
      if (this.floor !== 1) {
        param.belongId = this.floor
      }
      if (this.mobile) {
        param.mobile = this.mobile
      }
      // if (this.status && this.status !== 0) {
      //   param.belongState = this.status
      // }
      if (this.daterange) {
        param.start = this.daterange[0] + ' 00:00:00'
        param.end = this.daterange[1] + ' 23:59:59'
      }
      ApiClient.Post(ApiClient.Apis.GetDelegateImgList, param).then((e)=>{
        const list = e.data;
        import('@/utils/excel').then((excel)=>{
          const tHeader = ['委托号', '委托人手机号', '所属楼堂','是否无需操作', '提交时间']
          const filterVal = ['orderId', 'mobile', 'belong','faild', 'orderTime']
          const data = this.formatJson(filterVal, list)
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: this.filename,
            autoWidth: this.autoWidth,
            bookType: this.bookType,
          })
        })
      })
    },
    formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            if(j=="orderTime"){
              return parseTime(v[j]);
            }else if(j=="faild"){
              if(v[j]==0){
                return "是"
              }else{
                return "否"
              }
            }else{
              return v[j]
            }

            
          })
        )
      },
    GetDelegateList () {
      this.loading = true
      var param = {
        pageIndex: this.page.index,
        pageSize: this.page.size
      }
      if (this.floor !== 1) {
        param.belongId = this.floor
      }
      if (this.mobile) {
        param.mobile = this.mobile
      }
      // if (this.status && this.status !== 0) {
      //   param.belongState = this.status
      // }
      if (this.daterange) {
        param.start = this.daterange[0] + ' 00:00:00'
        param.end = this.daterange[1] + ' 23:59:59'
      }
      ApiClient.Post(ApiClient.Apis.GetDelegateList, param).then((e) => {
        this.loading = false
        this.lists = e.data
        // this.page = e.page
        this.page.total=e.totalCount;
      }).catch(err => {
        this.loading = false
        this.$alert(err.msg, '错误')
      })
    },
    pageTurning (index) {
      this.page.index = index
      this.GetDelegateList()
    }
  },
  created () {
    this.GetFloors()
    this.GetDelegateList()
  }
}
</script>
