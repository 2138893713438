<template>
  <div id="app" style="height:100%;">
    <router-view v-if="isRouteAlive"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  provide () {
    return {
      reload: this.relord
    }
  },
  data () {
    return {
      isRouteAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouteAlive = false
      this.$nextTick(() => {
        this.isRouteAlive = true
      })
    }
  }
}
</script>

<style>
@import './assets/css/common.css';
</style>
