<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="每日人数限制">
        <el-input  v-model="form.limitA"></el-input>
      </el-form-item>
      <el-form-item label="开始时间">
        <el-date-picker
          v-model="form.startDate"
          type="datetime"
          placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker
          v-model="form.endDate"
          type="datetime"
          placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="sub()">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ApiClient from '../../utils/apiclient'
import {parseTime} from '../../utils/index'

export default {
  filters: {
      timeFilter(times) {
        return parseTime(times)
      },
      faildFilter(v){
        if(v==0){
          return "是"
        }else{
          return "否"
        }
      }
    },
  data () {
    return {
      form:{
      },
      staticInfo:null,
      reCallStatic:null,

    }
  },
  methods: {
    fetchData(){
      let that=this;
      ApiClient.Post(ApiClient.Apis.GetDelegateSetting,{}).then((e) => {
        this.loading = false
        // this.lists = e.data
        that.form=e.data;
        console.log(e)
       
        // this.page = e.page
      }).catch(err => {
        this.loading = false
        this.$alert(err.msg, '错误')
      })
    },
    sub(){
      this.form.startTime=this.form.startDate
      ApiClient.Post(ApiClient.Apis.UpdateDelegateSetting,this.form).then((e) => {
        this.loading = false
        // this.lists = e.data
        // that.form=e.data;
        console.log(e)


       
        // this.page = e.page
      }).catch(err => {
        this.loading = false
        this.$alert(err.msg, '错误')
      })
    }

    
  },
  created () {
    this.fetchData()
  }
}
</script>
