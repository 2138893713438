<template>
  <div>
    

    <el-descriptions v-if="staticInfo!=null" class="margin-top" title="委托统计" :column="4"  border>
    
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        总委托数
      </template>
      {{ staticInfo.total }}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-mobile-phone"></i>
        已祭扫数
      </template>
      {{ staticInfo.done }}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-location-outline"></i>
        重复委托数
      </template>
      {{ staticInfo.repeat }}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-tickets"></i>
        点赞数
      </template>
      {{ staticInfo.good }}
    </el-descriptions-item>
    
  </el-descriptions>

  <el-descriptions class="margin-top" title="追思馆统计" :column="4"  border  style="margin-top:20px;">
    
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        总数量
      </template>
      {{reCallStatic.allCount}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-mobile-phone"></i>
        当日数量
      </template>
      {{reCallStatic.today}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-location-outline"></i>
        待审核
      </template>
      {{reCallStatic.daishenghe}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-tickets"></i>
        已审核
      </template>
      {{reCallStatic.yishenghe}}
    </el-descriptions-item>
    
  </el-descriptions>

  </div>
</template>

<script>
import ApiClient from '../../utils/apiclient'
import {parseTime} from '../../utils/index'

export default {
  filters: {
      timeFilter(times) {
        return parseTime(times)
      },
      faildFilter(v){
        if(v==0){
          return "是"
        }else{
          return "否"
        }
      }
    },
  data () {
    return {
      staticInfo:null,
      reCallStatic:null,

    }
  },
  methods: {
    getStatic(){
      let that=this;
      ApiClient.Post(ApiClient.Apis.GetStat,{}).then((e) => {
        this.loading = false
        // this.lists = e.data
        console.log(e)
        that.staticInfo=e.data

        // this.page = e.page
      }).catch(err => {
        this.loading = false
        this.$alert(err.msg, '错误')
      })
    },

    getRecallStatic(){
      let that=this;
      ApiClient.Post(ApiClient.Apis.GetRecallCount,{}).then((e) => {
        this.loading = false
        // this.lists = e.data
        console.log(e)
        that.reCallStatic=e.data

        // this.page = e.page
      }).catch(err => {
        this.loading = false
        this.$alert(err.msg, '错误')
      })
    }

    
  },
  created () {
    this.getStatic()
    this.getRecallStatic()
  }
}
</script>
