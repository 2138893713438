<template>
  <div style="height:100%;background:#f3f3f4;">
    <div class="body_div">
      <div class="body_div_center">
          <img style="width: 70px;height: 70px;" src="../assets/images/timglogo.png" />
          <span class="qu_tit">福州市殡仪馆管理后台</span>
          <span class="qu_tit1"></span>
          <input type="text" name="name" maxlength="11" v-model="mobile" class="login_input" placeholder="手机号" />
          <span class="sp_100 margintop10" style="vertical-align: bottom;">
                <input type="text" maxlength="6" name="name" id="smscode" v-model="smsCode" class="login_code" placeholder="输入验证码" />
                <a v-if="!smsDisable" class="a_code" @click="sendSMS">获取验证码</a>
                <a v-else class="a_code" style="background:#e8e8e8" >{{timeSpan}}s</a>
          </span>
          <a class="login_btn margintop10" @click="login">登&nbsp;录</a>
      </div>
    </div>
    <div style="position:absolute; left:0; width:100%; bottom:10px; text-align:center;">
        <p class="fontcolor666 fontsize16">版权所有：福州市殡仪馆</p>
    </div>
  </div>
</template>
<script>
import ApiClient from '@/utils/apiclient'
import Utils from '@/utils/apputils'

export default {
  data () {
    return {
      mobile: '',
      smsSN: '',
      smsCode: '',
      timeSpan: 0,
      smsDisable: false
    }
  },
  methods: {
    countdown (timeSpan) {
      this.smsDisable = true
      if (!timeSpan) timeSpan = 60
      this.timeSpan = timeSpan
      var interval = setInterval(() => {
        if ((this.timeSpan--) <= 0) {
          clearInterval(interval)
          this.smsDisable = false
        }
      }, 1000)
    },
    sendSMS () {
      // this.countdown(60)
      if (!Utils.checkTel(this.mobile)) {
        this.$alert('请输入正确的手机号', '错误')
        return
      }
      this.smsSN = Utils.randomString(6)
      if (this.timeSpan > 0) return
      this.startLoading()
      ApiClient.Post(ApiClient.Apis.SendSMS, {
        mobile: this.mobile,
        smsSN: this.smsSN,
        sign: 123456
      }).then(e => {
          console.log("返回数值了")
          console.log(e)
          if(e.status=="1"){
            this.countdown(60)
            this.endLoading()
          }else{
              this.endLoading()
            this.$alert(e.msg, '错误')
          }
        
      })
    },
    clear () {
      this.smsCode = ''
      this.smsDisable = true
    },
    login () {
        if(this.mobile==""){
            this.$alert("请输入手机号",'错误')
            return;
        }

        if(this.smsCode==""){
            this.$alert("请输入短信验证码",'错误')
            return;
        }
      this.startLoading()
      const param = {
        mobile: this.mobile,
        smsCode: this.smsCode,
      }
      ApiClient.Post(ApiClient.Apis.Login, param).then((e) => {
          if(e.status=="1"){
            // 记录登陆账号
            localStorage.setItem('mobile', this.mobile)
            ApiClient.SetToken(e.data)
            this.clear()
            this.endLoading()
            this.$router.push({name: 'hello'})
          }else{
              this.endLoading()
            this.$alert(e.msg, '错误')
          }
      }).catch(err => {
        this.endLoading()
        console.log(err)
      })
    }
  },
  created () {
    ApiClient.RemoveToken(true)
    this.$nextTick(() => {
      this.mobile = localStorage.getItem('mobile')
    })
  }
}
</script>
<style>
</style>
