<template>
  <div>
    <div class="formWork">
      <el-date-picker v-model="daterange" type="date" value-format="yyyy-MM-dd"  start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-button @click="search()" type="primary">查询</el-button>
      
    </div>
    <el-table class="margintop10" v-loading="loading" :data="lists">
      <el-table-column prop="refDate" label="日期" align="center">
      </el-table-column>
      <el-table-column prop="sessionCnt" label="打开次数" align="center">
      </el-table-column>
      <el-table-column prop="visitPv" label="访问次数" align="center">
      </el-table-column>
      <el-table-column prop="visitUv" label="访问人数" align="center">
      </el-table-column>
      <el-table-column prop="visitUvNew" label="新用户数" align="center">
      </el-table-column>
      
    </el-table>

    

  </div>
</template>

<script>
import ApiClient from '../../utils/apiclient'
import Utils from '../../utils/apputils'
import {parseTime} from '../../utils/index'

export default {
  filters: {
      timeFilter(times) {
        return parseTime(times)
      },
      faildFilter(v){
        if(v==0){
          return "是"
        }else{
          return "否"
        }
      }
    },
  data () {
    return {
      page: {
        index: 1,
        size: 10,
        count: 0,
        total: 0
      },
      daterange: '',
      FloorLists: [],
      floor: 1,
      statusLists: [{value: 0, name: '全部'}, {value: 1, name: '未分配'}, {value: 2, name: '已分配'}],
      status: 1,
      mobile: '',
      editmodel: {
        id: '',
        belongId: '',
        belong: ''
      },
      editFormVisible: false,
      showViewer: false,
      loading: false,
      url: [],
      lists: []
    }
  },
  methods: {
    search () {
      this.page.index = 1
      this.page.size = 10
      this.GetDelegateList()
    },
    reset () {
      this.page.index = 1
      this.page.size = 10
      this.mobile = ''
      this.status = 1
      this.daterange = ''
      this.floor = 1
      this.GetDelegateList()
    },
    onPreview (url) {
      this.showViewer = true
      this.url = [url]
    },
    closeViewer () {
      this.showViewer = false
    },
    switchStatus (val) {
      this.status = val
    },
    switchFloor (val) {
      this.floor = val
    },
    switchEditFloor (val) {
      var model = this.FloorLists[val]
      this.editmodel.belongId = model.id
      this.editmodel.belong = model.name
    },
    GetFloors () {
      var param = {
        types: JSON.stringify([1, 2, 3, 4])
      }
      ApiClient.Post(ApiClient.Apis.GetFloors, param).then((e) => {
        this.FloorLists = e.data
      })
    },
    statusText (val) {
      for (var i = 0; i < this.statusLists.length; i++) {
        if (val === this.statusLists[i].value) {
          return this.statusLists[i].name
        }
      }
      return ''
    },
    floorText (val) {
      for (var i = 0; i < this.FloorLists.length; i++) {
        if (val === this.FloorLists[i].id) {
          return this.FloorLists[i].name
        }
      }
      return ''
    },
    handleAllot (index) {
      var model = this.lists[index]
      this.editmodel = {
        id: model.id,
        belongId: model.belongId || this.FloorLists[1],
        belong: model.belong || this.FloorLists[1].name
      }
      this.editFormVisible = true
    },
    Belong () {
      if (this.editmodel.belongId === 1001 || this.editmodel.belongId === 1002) {
        var text = this.editmodel.belongId === 1001 ? '确认为非法委托吗？' : '确认为异常委托吗？'
        this.$prompt(text, '确认操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(({ value }) => {
          this.SetBelong(value)
        })
      } else {
        this.SetBelong()
      }
    },
    SetBelong (value) {
      if (!this.editmodel.belongId || this.editmodel.belongId === 1) {
        this.$msg({message: '请分配楼堂', type: 'warning'})
        return
      }
      var param = this.editmodel
      if (this.editmodel.belongId === 1001 || this.editmodel.belongId === 1002 || this.editmodel.belongId === 1000) {
        // if (!value) {
        //   this.$msg({message: '请输入原因', type: 'warning'})
        //   return
        // }
        if (value) {
          param.tip = value
        }
        param.state = 9
      } else if (this.editmodel.belongId !== 1000) {
        param.state = 10
      }
      ApiClient.Post(ApiClient.Apis.SetBelong, param).then(() => {
        this.GetDelegateList()
        this.editFormVisible = false
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    MarkDelegate (index) {
      var model = this.lists[index]
      var param = {
        id: model.Id,
        mark: model.Mark === 0 ? 1 : 0
      }
      ApiClient.Post(ApiClient.Apis.MarkDelegate, param).then(() => {
        this.GetDelegateList()
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    downImg () {
      var param = {}
      if (this.floor !== 1) {
        param.belongId = this.floor
      }
      if (this.mobile) {
        param.mobile = this.mobile
      }
      if (this.daterange) {
        param.start = this.daterange[0] + ' 00:00:00'
        param.end = this.daterange[1] + ' 23:59:59'
      }
      ApiClient.Post(ApiClient.Apis.GetDelegateImgList, param).then((e) => {
        var lists = e.data
        this.$confirm('确认下载' + lists.length + '张照片？', '确认操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var i = 0; i < lists.length; i++) {
            var item = lists[i]
            if (item.depositImg && item.mobile) {
              Utils.download(item.depositImg, item.mobile)
            }
          }
          // this.SetBelong()
        })
      }).catch(err => {
        this.$alert(err.msg, '错误')
      })
    },
    ExportDelegate () {
      var param = {}
      if (this.floor !== 1) {
        param.belongId = this.floor
      }
      if (this.mobile) {
        param.mobile = this.mobile
      }
      // if (this.status && this.status !== 0) {
      //   param.belongState = this.status
      // }
      if (this.daterange) {
        param.start = this.daterange[0] + ' 00:00:00'
        param.end = this.daterange[1] + ' 23:59:59'
      }
      ApiClient.Post(ApiClient.Apis.GetDelegateImgList, param).then((e)=>{
        const list = e.data;
        import('@/utils/excel').then((excel)=>{
          const tHeader = ['委托号', '委托人手机号', '所属楼堂','是否无需操作', '提交时间']
          const filterVal = ['orderId', 'mobile', 'belong','faild', 'orderTime']
          const data = this.formatJson(filterVal, list)
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: this.filename,
            autoWidth: this.autoWidth,
            bookType: this.bookType,
          })
        })
      })
    },
    formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            if(j=="orderTime"){
              return parseTime(v[j]);
            }else if(j=="faild"){
              if(v[j]==0){
                return "是"
              }else{
                return "否"
              }
            }else{
              return v[j]
            }

            
          })
        )
      },
    GetDelegateList () {

      console.log(this.daterange)
      this.loading = true
      var param = {
        start :this.daterange + ' 00:00:00'
       
      }
     
      ApiClient.Post(ApiClient.Apis.WechatStatics, param).then((e) => {
        this.loading = false
        this.lists = e.data
        // this.page = e.page
      }).catch(err => {
        this.loading = false
        this.$alert(err.msg, '错误')
      })
    },
    pageTurning (index) {
      this.page.index = index
      this.GetDelegateList()
    }
  },
  created () {
    this.GetFloors()
    this.GetDelegateList()
  }
}
</script>
